<template>
  <div class="errorParsing">
    <div v-show="!isCard">
      <examinationHeader ref="examinationHeader" :answerCard="true" :paperAnswerTime="paperAnswerTime" :showTime="true" :id="$route.query.id" :type="$route.query.type" @headerClick="headerClick"></examinationHeader>
      <van-loading class="loading_bg" type="spinner" v-show="activeLoad"/>
      <chapterDetailCom ref="chapterDetailCom" :AnswerList="AnswerList" :isNext="isNext"
                        :fontType="fontType" :isAnaly="isAnaly_val" :questionType="questionType"></chapterDetailCom>
    </div>

    <div v-if="isCard">
      <answerCard @hideCard="hideCard"></answerCard>
    </div>
  </div>
</template>

<script>
import {subjectAnalysisList,exerciseSubjectAnalysisList} from "@/api/questionBank.js";
import chapterDetailCom from '../chapter/chapterDetail.vue';
import examinationHeader from '../examinationHeader/examinationHeader.vue';
import answerCard from '../answerCard/answerCard';
export default {
  methods: {
    init() {
      this.questionType = this.$route.query.type;
      if(this.$route.query.question === 'chapterExercises') {
        let params = {
          nodeId: this.$route.query.id,
          nodeType: '1',
          type: this.$route.query.type === 'error' ? 1 : 2
        };
        exerciseSubjectAnalysisList(params).then(res=>{
          this.activeLoad = false;
          this.$store.commit('setQuestionSwipeIndex',{index:1});
          this.AnswerList = {
            countNumber: res.data.data.length,
            paperSubjectList: res.data.data
          };
        })
      }else if(this.$route.query.question === 'testPaper') {
        let params = {
          testPaperId: this.$route.query.id,
          type: this.$route.query.type === 'error' ? 1 : 2
        };
        subjectAnalysisList(params).then(res=>{
          this.activeLoad = false;
          this.$store.commit('setQuestionSwipeIndex',{index:1});
          this.AnswerList = {
            countNumber: res.data.data.length,
            paperSubjectList: res.data.data
          };
        })
      }
    },
    hideCard(item) {
      this.isCard = false;
      if(item) {
        this.$refs.chapterDetailCom.setSwiptTo(parseInt(item.index));
      }
    },
    headerClick(item) {
      if (item.type === 'hand') {
        // 交卷
        assignment_paper(this.$route.query.id,1).then((res)=>{
          if(res.data.code == 0){
            this.$refs.examinationHeader.hideHand();
            //答题报告
            this.$router.push({
              path:"/answerReport",
              query:{
                id:this.$route.query.id,
                path:"/chapterExercisesDetail",
                type: 'chapterExercises'
              }
            })
          }
        })

      } else if (item.type === 'stop') {
        // 停止做题
        this.$refs.examinationHeader.hideHand();
      } else if (item.type === 'answerCard') {
        // 答题卡
        this.isCard = true;
      } else if (item.type === 'analysis') {
        // 答案解析
        let indexs = this.$refs.chapterDetailCom.getCurnet();
        this.$store.commit('setQuestionSwipeIndex',{index:indexs});
        this.isAnaly_val = 'yes';

      }
    }
  },
  components: {
    chapterDetailCom,
    examinationHeader,
    answerCard
  },
  data() {
    return {
      AnswerList: {
        paperSubjectList: []
      },
      isNext: false,
      fontType: 'in',
      isAnaly_val:'no',
      paperAnswerTime:'',
      activeLoad:true,
      isCard: false,
      questionType: ''
    }
  },
  name: 'questionParsing',
  created() {
    this.init();
    this.$store.commit('setQuestionSwipeIndex',{index:-1});
  }
}
</script>

<style lang="less" scoped>
.chapterDetail {
  width: 100%;
  background: #f5f5f5;
}
.loading_bg{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center
}
</style>
